import webButtonsGen from "../assets/projects-images/generador-de-botones-css.png";
import edeptec from "../assets/projects-images/edeptec.png";
import ahorcado from "../assets/projects-images/juego del ahorcado.png";
import toDoList from "../assets/projects-images/to-do-list-vanilla-js.jpg";
import arduinoProjects from "../assets/projects-images/Proyectos con arduino portfolio img.jpg";
import picProjects from "../assets/projects-images/Proyectos con pic portfolio img.jpg";
import visualBasic from "../assets/projects-images/proyectos-visual-basic-portfolio.jpg";
import gallery from "../assets/projects-images/Galeria de diseños.png";
import traslator from "../assets/projects-images/clave-murcielago-a-español.jpg";
import passWordGenerator from "../assets/projects-images/password-generator-interface-2.jpg";
import friendsCardsGame from "../assets/projects-images/friends-cards-game.jpg";

// icons

import html from "../assets/icons/icons8-html-5.svg";
import css from "../assets/icons/icons8-css3.svg";
import jquery from "../assets/icons/icons8-jquery.svg";
import reactIcon from "../assets/icons/icons8-react.svg";
import javaScript from "../assets/icons/icons8-javascript.svg";
import arduino from "../assets/icons/icons8-arduino.svg";
import nextJs from "../assets/icons/icons8-nextjs.svg";
import photoShop from "../assets/icons/icons8-photoshop.svg";
import blogger from "../assets/icons/icons8-blogger.svg";
import canva from "../assets/icons/icons8-canva.svg";
import visualbasic from "../assets/icons/icons8-visual-basic-96.png";
import bluetooth from "../assets/icons/icons8-bluetooth-60.png";

const projectsDb = [
  {
    title: "Generador de Botones para web",
    src: webButtonsGen,
    text: "Generador de estilos para botones web.",
    techStack: [html, css, javaScript, jquery],
    url: "https://buttongen.edeptec.com/",
  },
  {
    title: "EDEPTEC",
    src: edeptec,
    text: "Blog personal alojado en blogger.",
    techStack: [
      html,
      css,
      javaScript,
      jquery,
      blogger,
      photoShop,
      canva,
      arduino,
    ],
    url: "https://www.edeptec.com",
  },
  {
    title: "Juego del ahorcado",
    src: ahorcado,
    text: "Juego del ahorcado, desarrollado con React JS",
    techStack: [reactIcon],
    url: "https://ahorcado.edeptec.com",
  },
  {
    title: "To Do List",
    src: toDoList,
    text: "To Do List. Con almacenamiento de tareas en local storage.",
    techStack: [html, css, javaScript],
    url: "https://estebancarrillog.github.io/to-do-list",
  },
  {
    title: "Galeria de diseños",
    src: gallery,
    text: "Galeria de diseños realizados con photoshop y canva.",
    techStack: [html, css, javaScript],
    url: "https://estebancarrillog.github.io/designs-gallery",
  },
  {
    title: "Traductor de clave murcielago",
    src: traslator,
    text: "Traductor de clave murcielago a español y viceversa.",
    techStack: [reactIcon],
    url: "https://clavemurcielago.edeptec.com/",
  },
  {
    title: "Generador de Contraseñas",
    src: passWordGenerator,
    text: "Generador de contraseñas seguras.",
    techStack: [html, css, javaScript],
    url: "https://passwordgenerator.edeptec.com/",
  },
  {
    title: "Friends Cards Juego",
    src: friendsCardsGame,
    text: "Adaptacion web del juego verdad o reto.",
    techStack: [nextJs],
    url: "https://friendscards.edeptec.com/",
  },
];

const projectsListsDb = [
  {
    title: "Proyectos en Visual Basic 6.0",
    src: visualBasic,
    text: "Coleccion de proyectos realizados con Visual Basic programados en Basic",
    techStack: [visualbasic],
    url: "https://www.edeptec.com/p/proyectos-con-visual-basic.html",
    btnText: "Ver lista de proyectos",
  },
  {
    title: "Proyectos con Arduino",
    src: arduinoProjects,
    text: "Coleccion de proyectos realizados con arduino programados en C++",
    techStack: [arduino, bluetooth],
    url: "https://www.edeptec.com/p/proyectos-con-arduino.html",
    btnText: "Ver lista de proyectos",
  },
  {
    title: "Proyectos con PIC",
    src: picProjects,
    text: "Coleccion de proyectos realizados con pic programados en Basic",
    url: "https://www.edeptec.com/p/proyectos-con-pic.html",
    btnText: "Ver lista de proyectos",
  },
];

export { projectsDb, projectsListsDb };
