import {
    FaFacebookF,
    FaInstagram,
    FaLinkedinIn,
    FaGithub,
  } from "react-icons/fa6";
function SocialIcons() {
  return (
    <>
      <a className="social-icon" href="https://www.facebook.com/profile.php?id=100059001002380">
        <FaFacebookF />
      </a>
      <a className="social-icon" href="https://www.instagram.com/estebanc.dev">
        <FaInstagram />
      </a>
      <a className="social-icon" href="https://www.linkedin.com/in/estebanc96">
        <FaLinkedinIn />
      </a>
      <a className="social-icon" href="https://github.com/estebancarrillog">
        <FaGithub />
      </a>
    </>
  );
}

export default SocialIcons;
